import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowUp,
  faArrowDown,
  faChartColumn,
  faCheckCircle,
  faCircleUser,
  faCog,
  faEdit,
  faEye,
  faEyeSlash,
  faFileArrowUp,
  faFilter,
  faGripLines,
  faInfoCircle,
  faLock,
  faMagnifyingGlass,
  faMinusCircle,
  faPen,
  faPlus,
  faPlusCircle,
  faReceipt,
  faSliders,
  faSort,
  faTimes,
  faTimesCircle,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'

import {
  faBuildings,
  faGrid,
  faSlider,
  faGripDotsVertical,
  faCircleExclamation
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowUp,
  faArrowDown,
  faBuildings,
  faChartColumn,
  faCheckCircle,
  faCircleUser,
  faCog,
  faEdit,
  faEye,
  faEyeSlash,
  faFileArrowUp,
  faFilter,
  faGrid,
  faGripLines,
  faInfoCircle,
  faLock,
  faMagnifyingGlass,
  faMinusCircle,
  faPen,
  faPlus,
  faPlusCircle,
  faReceipt,
  faSliders,
  faSlider,
  faSort,
  faTimes,
  faTimesCircle,
  faGripDotsVertical,
  faCircleExclamation,
  faSpinner
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
