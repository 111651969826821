import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";

export default class extends Controller {
  static values = {
    level: String,
  };

  connect() {
    let duration = 3000;

    if (this.levelValue == "alert") {
      duration = -1;
    }

    Toastify({
      text: this.element.innerHTML,
      duration: duration,
      className: `toast-${this.levelValue}`,
      escapeMarkup: false,
      close: true,
      position: "left",
      gravity: "bottom",
    }).showToast();

    this.element.remove();
  }
}
