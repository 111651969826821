// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers/application";
import "./controllers/shared";
import tippy from "tippy.js";
import Swal from "sweetalert2";

import "trix";
import "@rails/actiontext";

Trix.config.blockAttributes.default.tagName = "p";
Trix.config.blockAttributes.default.breakOnReturn = true;

import "./icons";
