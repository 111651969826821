import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  lastIndex = null;
  reverse = false;

  get tableHead() {
    return this.element.tHead;
  }

  get tableHeaders() {
    return Array.from(this.tableHead.rows[0].cells);
  }

  get tableBody() {
    return this.element.tBodies[0];
  }

  get tableRows() {
    return Array.from(this.tableBody.rows);
  }

  initialize() {
    this.sort = this.sort.bind(this);
  }

  connect() {
    requestAnimationFrame(() => {
      this.tableHeaders.forEach((cell, index) => {
        if (cell.dataset.sortable != "false") {
          cell.addEventListener("click", this.sort);
        }

        if (cell.dataset.direction) {
          // ensure consistent initial sorting
          this.lastIndex = index
          this.sortByColumn(index, true)
        }
      });
    });
  }

  disconnect() {
    this.tableHeaders.forEach(cell => cell.removeEventListener("click", this.sort));
  }

  sort(event) {
    event.preventDefault();
    let headerCell = event.target;
    let headerCellIndex = this.indexOfHeaderCell(headerCell);
    this.sortByColumn(headerCellIndex);
  }

  indexOfHeaderCell(cell) {
    return this.tableHeaders.indexOf(cell);
  }

  sortByColumn(index, initial=false) {
    let frag = document.createDocumentFragment();
    let rows = this.tableRows;
    let reverse = false;

    let sortDirection = this.tableHeaders[index].dataset.direction

    if (initial) {
      // ensure consistent sorting on first load, no direction inversion
      reverse = (sortDirection == "desc")

    } else {
      // invert the current sort direction
      if (sortDirection === "desc") {
        // now sort ascending
        this.tableHeaders[index].dataset.direction = "asc"
      } else {
        // now sort descending (the default)
        this.tableHeaders[index].dataset.direction = "desc"
        reverse = true
      }
    }

    // remove sort direction from previous column if changed
    if (index != this.lastIndex) {
      delete this.tableHeaders[this.lastIndex].dataset.direction
    }

    let newRows = rows.sort((row, otherRow) => {
      let cells = row.cells;
      let otherCells = otherRow.cells;

      if (row.dataset.sortTop || otherRow.dataset.sortBottom) {
        return -1;
      }
      if (row.dataset.sortBottom || otherRow.dataset.sortTop) {
        return 1;
      }

      let x = cells[index]?.dataset.value || cells[index]?.innerText || "";
      let y = otherCells[index]?.dataset.value || otherCells[index]?.innerText || "";

      let sortVal = x.localeCompare(y, "en", {sensitivity: "base", numeric: true, caseFirst: "upper"});

      if (reverse) {
        return sortVal > 0 ? -1 : 1;
      }

      return sortVal;
    });

    newRows.forEach(row => frag.appendChild(row));

    this.tableBody.innerHTML = "";
    this.tableBody.appendChild(frag);
    this.lastIndex = index;
  }

}
