import c0 from './autosize_controller';
import c1 from './autosubmit_controller';
import c2 from './clipboard_controller';
import c3 from './file_upload_controller';
import c4 from './password_peek_controller';
import c5 from './refresh_controller';
import c6 from './swal_confirm_controller';
import c7 from './table_sortable_controller';
import c8 from './toggle_controller';
export const definitions = [
	{identifier: 'autosize', controllerConstructor: c0},
	{identifier: 'autosubmit', controllerConstructor: c1},
	{identifier: 'clipboard', controllerConstructor: c2},
	{identifier: 'file-upload', controllerConstructor: c3},
	{identifier: 'password-peek', controllerConstructor: c4},
	{identifier: 'refresh', controllerConstructor: c5},
	{identifier: 'swal-confirm', controllerConstructor: c6},
	{identifier: 'table-sortable', controllerConstructor: c7},
	{identifier: 'toggle', controllerConstructor: c8},
];
