// app/javascript/controllers/clipboard_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["source", "trigger"]

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value)

    this.sourceTarget.focus()
    const triggerElement = this.triggerTarget
    const initialHTML = triggerElement.innerHTML
    triggerElement.innerHTML = "Copied"
    setTimeout(() => {
      triggerElement.innerHTML = initialHTML
      // unfocus
      this.sourceTarget.blur()
    }, 2000)
  }
}
