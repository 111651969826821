import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static values = {
    src: String,
    delay: {type: Number, default: 2}
  }

  connect() {
    this.timeout = setTimeout(() => {
      this.element.closest('turbo-frame').setAttribute('src', this.srcValue)
    }, this.delayValue * 1000)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
